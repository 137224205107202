import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonService } from '../common/common.service';
import { ApiResponse } from '../../model/api-response.models';
import { catchError, retry } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private apiURL: string = environment.API_ENDPOINT;

  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private router: Router
  ) {}

  httpOptions = {
    headers: new HttpHeaders({
      Authorization: 'bearer ' + localStorage.getItem('token'),
    }),
  };

  adminLogin(obj: any) {
    return this.http
      .post<ApiResponse>(this.apiURL + 'admin/admin_login', obj)
      .pipe(retry(1), catchError(this.commonService.handleError));
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/auth/login']);
  }
}

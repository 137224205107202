import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { AdminService } from '../admin/admin.service';

@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private adminService: AdminService
  ) {
    this.angularFireMessaging.messaging.subscribe((_messaging) => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    });
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe({
      next: (token) => {
        const obj = {
          fkAdminId: localStorage.getItem('userId'),
          FCMToken: token,
        };
        this.adminService.updateFCM(obj).subscribe((res) => {});
      },
      error: (err) => {
        console.error('Unable to get permission to notify.', err);
      },
    });
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload: any) => {
      this.currentMessage.next(payload);
    });
  }

  // requestPermission(): Promise<string> {
  //   return new Promise((resolve, reject) => {
  //     if (!('Notification' in window)) {
  //       console.log('This browser does not support notifications');
  //       reject('This browser does not support notifications.');
  //     } else if (Notification.permission === 'granted') {
  //       console.log('granted');
  //       this.requestPermissions();
  //       resolve(Notification.permission);
  //     } else if (Notification.permission !== 'denied') {
  //       console.log('denied');
  //       Notification.requestPermission().then((permission) => {
  //         if (permission === 'granted') {
  //           console.log('granted 2');
  //           this.requestPermissions();
  //           resolve(permission);
  //         } else {
  //           console.log('denied 2');
  //           reject('Permission for notifications denied.');
  //         }
  //       });
  //     } else {
  //       console.log('Permission for notifications denied');
  //       reject('Permission for notifications denied.');
  //     }
  //   });
  // }

  sendNotification(title: string, options?: NotificationOptions): void {
    if (!('Notification' in window)) {
      console.error('This browser does not support notifications.');
    } else if (Notification.permission === 'granted') {
      new Notification(title, options);
    } else {
      console.error('Permission for notifications denied.');
    }
  }
}

import { Component } from '@angular/core';
import { locale as arLang } from '../app/modules/i18n/vocabs/ar';
import { TranslationService } from './modules/i18n';
import { locale as enLang } from './modules/i18n/vocabs/en';

@Component({
  // tslint:disable-next-line:component-selector
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(private translationService: TranslationService) {
    this.translationService.loadTranslations(enLang, arLang);
  }
}
